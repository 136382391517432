@import "~styles/includes/index.scss";
.breadcrumbs {
	color: $color-primary-blue;
	font-weight: 500 !important;
	font-size: 14px;
	line-height: 22px;

	.divider {
		margin: 0 4px;
		text-decoration: none !important;
	}
}

.breadcrumb {
	text-transform: capitalize;

	max-width: 600px;

	&:not(:last-child) {
		&::after {
			content: '/';
			margin: 0 4px;
			color: $color-primary-blue;
		}
	}

	&.link {
		a {
			text-decoration-line: underline;
			color: $color-primary-blue;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-transform: capitalize;
		}
	}
}
