@import "~styles/includes/index.scss";
.ctaModule {
	&.dark {
		color: white;

		h2 {
			color: white;
		}
	}
	&:not(.dark) {
		color: $color-primary-blue;

		h2 {
			color: $color-primary-blue;
		}
	}

	&.noMedia {
		.moduleContent {
			grid-template-columns: 1fr;
		}

		.content {
			flex-direction: row;
			align-items: center;

			@media (max-width: (960px - 1)) {
				flex-direction: column;
				align-items: flex-start;
			}

			.text {
				max-width: 740px;
				margin-right: 40px;

				@media (max-width: (960px - 1)) {
					max-width: 100%;
					margin-right: 0;
				}
			}

			.buttons {
				justify-content: center;
				margin-left: auto;
				margin-top: 0;

				@media (max-width: (960px - 1)) {
					margin-left: -8px;
					margin-top: 24px;

					width: 100%;
				}
			}
		}
	}
}

.moduleContent {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 80px;
	align-items: center;

	@media (max-width: (960px - 1)) {
		grid-template-columns: 1fr;
		grid-gap: 40px;
	}
}

.image {
	display: flex;
	width: 100%;
	min-height: 296px;
	overflow: hidden;
	border-radius: $global-border-radius;
	position: relative;
	order: -1; // Image must be located below heading in the HTML (WCAG)

	@media (max-width: (960px - 1)) {
		order: initial;
	}

	&.rightImage {
		order: initial;
	}

	&,
	iframe {
		height: 100%;
	}
}

.content {
	display: flex;
	flex-direction: column;

	.text {
		display: flex;
		flex-direction: column;

		.description {
			font-size: 18px;
			line-height: 32px;
			margin-top: 24px;
			white-space: pre-wrap;
		}
	}

	.buttons {
		display: flex;
		flex-wrap: wrap;
		margin: -8px;
		margin-top: 40px;

		.link {
			display: flex;
		}

		@media (max-width: (960px - 1)) {
			margin-top: 24px;
		}

		@media (max-width: $breakpoint-xsmall-max) {
			.link {
				&,
				> a,
				button {
					width: 100%;
				}

				width: 100%;
			}
		}

		.link {
			padding: 8px;
			width: 100%;
			> button,
			a {
				&:not(:last-child) {
					margin-right: 8px;
				}
			}
		}
	}
}
