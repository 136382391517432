@import "~styles/includes/index.scss";
.tags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	margin-top: 16px;

	@media (max-width: $breakpoint-medium) {
		margin: 16px 0;
		height: auto;
	}

	.tag {
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		color: $color-primary-blue;

		margin-left: 8px;

		@media (max-width: $breakpoint-medium) {
			font-size: 18px;
			line-height: 24px;
		}
	}

	.tag:first-of-type {
		margin-left: 16px;
	}
}
