@import "~styles/includes/index.scss";
.Linkbox {
	display: flex;
	flex-direction: column;

	&.dark {
		color: white;

		div {
			color: white;
		}
	}

	&:not(.dark) {
		color: $color-primary-blue;

		div {
			color: $color-primary-blue;
		}
	}
}

.title {
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	max-width: 740px;
	color: $color-primary-blue;

	@media (max-width: $breakpoint-xsmall-max) {
		font-size: 22px;
		line-height: 28px;
	}
}

.description {
	margin-top: 16px;
	max-width: 740px;
	font-size: 18px;
	line-height: 32px;

	@media (max-width: $breakpoint-xsmall-max) {
		margin-top: 8px;
		line-height: 24px;
		font-size: 14px;
	}
}

.links {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-template-rows: 1fr;
	align-items: center;
	margin-top: 48px;
	gap: 16px;

	@media (max-width: $breakpoint-xsmall-max) {
		gap: 0;
		grid-template-columns: 1fr;
		margin-top: 24px;
	}

	.button {
		@media (max-width: $breakpoint-xsmall-max) {
			margin-bottom: 12px;
			width: 100%;
		}
	}
}
