@import "~styles/includes/index.scss";
$height-sidebar-fragment: 64px;
$height-sidebar-fragment-small: 32px;

.introHeader {
	width: 100%;
}

.background {
	&White {
		@include backgroundWhite();
	}
	&Grey {
		@include backgroundGrey();
	}
	&Purple {
		@include backgroundPurple();
	}
}

.header {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 64px 0;

	z-index: 90;

	@media (max-width: $breakpoint-medium) {
		display: block;
		padding: 32px 0;
	}
}

.content {
	position: relative;
	display: flex;
	width: 100%;
	z-index: 90;

	@media (max-width: $breakpoint-medium-max) {
		position: initial;
		width: 100%;
	}
}

.sidebar {
	position: absolute;
	left: -36px;

	@media (max-width: $breakpoint-medium-max) {
		left: 0px;
	}

	div:nth-child(1) {
		background: #005b7f;
	}
	div:nth-child(2) {
		background: #48a842;
	}
	div:nth-child(3) {
		background: #ffdd00;
	}
	div:nth-child(4) {
		background: #000000;
	}

	div {
		content: '';
		width: 12px;
		height: $height-sidebar-fragment;

		@media (max-width: $breakpoint-medium-max) {
			width: 5px;
			height: $height-sidebar-fragment-small;
		}
	}
}

.main {
	display: flex;
	flex-direction: column;
	max-width: 740px;
	min-height: $height-sidebar-fragment * 4;

	@media (max-width: $breakpoint-medium-max) {
		min-height: $height-sidebar-fragment-small * 4;
	}

	.breadcrumb {
		margin-bottom: 16px;
		@media (max-width: $breakpoint-medium) {
			max-width: 100%;
			margin-bottom: 8px;
		}
	}

	.title {
		max-width: 740px;

		h1 {
			margin: 0;
		}
	}

	.excerpt {
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		color: $color-primary-blue;
		margin-top: 24px;
		max-width: 740px;

		@media (max-width: $breakpoint-medium-max) {
			margin-top: 16px;
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.noDescription {
	min-height: $height-sidebar-fragment * 2;

	@media (max-width: $breakpoint-medium-max) {
		min-height: $height-sidebar-fragment-small * 2;
	}
}

.cover {
	width: 35%;
	height: 100%;
	z-index: 2;
	position: absolute;
	right: 0;

	img {
		object-fit: cover;
	}

	@media (max-width: $breakpoint-medium-max) {
		width: calc(100% + 32px);
		height: auto;
		position: relative;
		margin-left: -16px;
		padding-top: 24px;

		min-height: 320px !important;

		img {
			max-height: 320px !important; // NextJS Image inline override
			height: auto !important; // NextJS Image inline override
		}

		img,
		> div {
			position: static !important; // NextJS Image inline override
		}
	}
}

@media (max-width: $breakpoint-medium-max) {
	.noCover {
		height: 0;
	}
}
